import styled from 'styled-components';

import { Line } from 'components/Line';

import { getDimension } from 'styled/helpers/getDimension';

export const SliderDot = styled.div`
  border-radius: 50%;
  background-color: ${props => props.theme.colors.orange};
  width: ${getDimension('size3')};
  height: ${getDimension('size3')};
  box-shadow: 0 0 0 1px ${props => props.theme.colors.white};
  z-index: 2;
  position: relative;
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 0 -${getDimension('size1')};
  }
`;

export const SliderSpacer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${getDimension('size4')} 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: ${getDimension('size2')} 0;
  }
`;

/** A slightly styled line to adjust the way in which the slider nodes position along it */
export const SliderLine = styled(Line)`
  flex: 1;
`;

/** A slightly styled line to adjust the way in which the slider nodes position along it */
export const SliderBlank = styled.div`
  flex: 1;
`;

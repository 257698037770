import styled, { css } from 'styled-components';
import { lighten } from 'polished';

import { getDimension } from 'styled/helpers/getDimension';

const beforeMixin = css`
  &::before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${props => lighten(0.35, props.theme.colors.primary)};
    border-bottom: 10px solid transparent;
    position: absolute;
    bottom: -${getDimension('size5')};
    left: 50%;
    margin-left: -10px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      bottom: -10px;
      border-width: 5px;
      margin-left: -5px;
    }
  }
`;

export const Price = styled.h3`
  position: relative;
  background-color: ${props => lighten(0.35, props.theme.colors.primary)};
  color: ${props => props.theme.colors.white};
  padding: ${getDimension('size2')};
  margin: 0px ${getDimension('size2')};
  text-align: center;
  ${beforeMixin}

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${getDimension('size1')};
    margin: 0px ${getDimension('size1')};
  }
`;

import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { getLocalizedPath } from 'helpers/url';

import { Box } from 'components/Box';
import { BaseButton } from 'components/Button';
import { Container, Card, ImageContainer } from './ImageCard.styled';

import type { FC, ReactNode } from 'react';
import type { Highlight } from 'styled/types';

export interface ImageCardProps {
  title: String;
  content: ReactNode;
  background: Highlight;
  image: ReactNode;
  /**
   * The link to be attatched `FlexibleLinkComponent` that acts as the container for the card.
   *  If the link is external then `localize: false` must also be passed to prevent error
   */
  to: string;
  /** Whether or not the `to` link should be localised, `true` by default. */
  localize?: boolean;
  showButton?: boolean;
  buttonText?: string;
  fixedImageHeight?: boolean;
}

export const ImageCard: FC<ImageCardProps> = ({
  title,
  content,
  background,
  image,
  to,
  localize = true,
  showButton,
  buttonText = 'Learn more',
  fixedImageHeight = true,
}) => {
  const { currentLocale } = useLocalizationContext();

  const link = localize ? getLocalizedPath({ path: to, locale: currentLocale }) : to;

  return (
    <Container to={link}>
      <Card background={background}>
        <ImageContainer fixedImageHeight={fixedImageHeight}>{image}</ImageContainer>
        <Box padding={6} paddingTop={0}>
          <h2>{title}</h2>
          {content}
        </Box>

        {showButton && (
          <Box marginTop="auto" padding={6}>
            {/* We don't use an actual button or link here because the parent card container is a button/link
            the ButtonDiv is just for presentational purposes - giving a button appearance  */}
            <BaseButton
              as="div"
              className="button-div"
              appearance="secondary"
              containerBackground="positive"
              showArrowIcon
            >
              {buttonText}
            </BaseButton>
          </Box>
        )}
      </Card>
    </Container>
  );
};

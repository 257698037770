import styled from 'styled-components';

export const Text = styled.p`
  color: ${props => props.theme.colors.white};
  max-width: 350px;
  margin-bottom: 0px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`;

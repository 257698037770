import { useGatsbyImage } from 'hooks/useGatsbyImage';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { SignupLink } from 'components/Links';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { PricingSlider } from 'components/PricingSlider';
import { ImageCards } from 'components/ImageCard';

import { TrustPilot } from 'components/TrustPilot';
import { accountantsAdvisersCards } from 'page-data/professionals';

import CtaGraph from 'images/shared-page-assets/ctagraph.inline.svg';

import type { FC } from 'react';

export const Professionals: FC = () => (
  <Layout hideFooterCta>
    <Seo />

    <HeroContainer background="navyBlue">
      <Section>
        <h1>Built for accountants, advisers & professionals</h1>
        <p>
          Manage multiple client or personal portfolios in one place. Automatically track trades,
          dividend payments/corporate actions and performance across all your portfolios.
        </p>

        <br />

        <SignupLink asButton appearance="primary" containerBackground="negative" showArrowIcon />
      </Section>

      <SectionImage
        image={useGatsbyImage({
          name: 'professionals/hero',
          loading: 'eager',
          alt: "User Interface of Sharesight Professional's portfolio and dividend tracker for accountants and financial advisers",
        })}
        boxShadow={false}
      />
    </HeroContainer>

    <Container background="blue">
      <ImageCards cards={accountantsAdvisersCards} gap={15} />
    </Container>
    <Container background="navyBlue">
      <SectionImage
        image={useGatsbyImage({
          name: 'professionals/managing-multiple-portfolios',
          alt: "Screenshot of Sharesight's reports page on laptop screen",
        })}
        boxShadow={false}
      />
      <Section>
        <h2>Manage multiple portfolios with your desktop, not your desk</h2>
        <p>
          Quickly check any client portfolio at any time. We automate the tedious tasks so you can
          focus on growing your business.
        </p>
      </Section>
    </Container>

    <PricingSlider />

    <TrustPilot />

    <Container background="navyBlueHighlight">
      <Section>
        <h2>Automate your practice with a 30-day free trial</h2>
        <p>No credit card needed.</p>
        <br />
        <SignupLink asButton appearance="primary" containerBackground="negative" showArrowIcon />
      </Section>

      <SectionImage image={<CtaGraph />} boxShadow={false} />
    </Container>
  </Layout>
);

export default Professionals;

import styled, { css } from 'styled-components';
import { GridContainer } from 'components/Container';

export const ImageCardsGrid = styled(GridContainer)<{ columns: 2 | 3 }>`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }

  ${({ columns }) =>
    columns === 2 &&
    css`
      grid-template-columns: 0.25fr 1fr 1fr 0.25fr;

      a:nth-child(1) {
        grid-column-start: 2;
      }

      ${({ theme }) => theme.breakpoints.down('lg')} {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;

        a:nth-child(1) {
          grid-column-start: 1;
        }
      }

      ${({ theme }) => theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
        grid-auto-flow: row;

        a:nth-child(1) {
          grid-column-start: 1;
        }

        > * {
          span: 1;
        }
      }
    `}
`;

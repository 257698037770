import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Box } from 'components/Box';
import { Container } from './Container.styled';
import { Header } from './Header.styled';
import { Text } from './Text.styled';
import { TextContainer } from './TextContainer.styled';

import { Slider } from './Slider';

import type { FC } from 'react';

export const PricingSlider: FC = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const {
    priceIncludesTax,
    translations: { currencyCode },
  } = useContentfulLocale();

  let taxText = `*Prices in ${currencyCode}`;
  taxText += !priceIncludesTax ? '. Taxes may apply.' : ' excluding GST.';

  return (
    <Box
      background="blue"
      padding={isMobile ? 10 : 15}
      paddingLeft={isMobile ? 5 : 12}
      paddingRight={isMobile ? 5 : 12}
    >
      <Container id="pricing">
        <TextContainer>
          <Header>Pay as you need pricing</Header>
          <Text>A plan that can scale with your business.</Text>
          <Text>
            <em>{taxText}</em>
          </Text>
        </TextContainer>
        <Slider />
      </Container>
    </Box>
  );
};

import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

export const Entry = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  gap: ${getDimension('size2')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: ${getDimension('size1')};
  }
`;

export const EntryText = styled.div`
  display: none;
  color: ${props => props.theme.colors.white};
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: initial;
  }
`;

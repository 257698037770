import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  max-width: 100%;
  gap: ${getDimension('size3')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
  }
`;

import styled from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${getDimension('size8')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: ${getDimension('size10')};
    align-items: stretch;
  }
`;

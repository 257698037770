import ColumnGraphsPieChart from 'images/shared-page-assets/column-graphs-pie-chart.inline.svg';
import PieChartHalvedSpheres from 'images/shared-page-assets/pie-chart-halved-spheres.inline.svg';
import type { ImageCardProps } from 'components/ImageCard';

export const accountantsAdvisersCards: ImageCardProps[] = [
  {
    title: 'Accountants',
    content: (
      <>
        Save time with automated dividend tracking and comprehensive tax reporting at the click of a
        button.
      </>
    ),
    background: 'navyBlueHighlight',
    image: <ColumnGraphsPieChart />,
    to: '/accountants/',
    showButton: true,
  },
  {
    title: 'Financial advisers',
    content: (
      <>
        Deepen client relationships. Reveal their real-time performance in one easy to share
        dashboard.
      </>
    ),
    background: 'navyBlueHighlight',
    image: <PieChartHalvedSpheres />,
    to: '/financial-advisers/',
    showButton: true,
  },
];
